import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isLoggedIn: false,
    filters: {
      general: {
        dateRange: 'year',
        customFilters: {},
      },
      event: {
        dateRange: 'year',
        selectedEvent: null,
      },
      user: {
        searchQuery: '',
      },
      year: null,
      month: null
    },
    paneles: {
      general: [
        { name: 'eventsCountByYearMonth', visible: true },        
        { name: 'eventsCountByLocation', visible: true },
      ],
      event: [],
      user: [],
    },
    isFirstLoad: true,
  },
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setFilter(state, { type, filter }) {
      state.filters[type] = filter;
    },
    togglePanel(state, { panelType, panelName }) {
      const panel = state.paneles[panelType].find(p => p.name === panelName);
      if (panel) {
        panel.visible = !panel.visible;
      }
    },
    setYear(state, year) {
      state.filters.year = year;
    },
    setMonth(state, month) {
      state.filters.month = month;
    },
    setFirstLoad(state, isFirstLoad) {
      state.isFirstLoad = isFirstLoad;
    },
    updateAllPanels(state, newPanels) {
      state.paneles = newPanels;
    },
    applyFilters({ commit }, { year, month }) {
      commit('setYear', year);
      commit('setMonth', month);
    }
  },
  actions: {
    login({ commit }) {
      commit('setLoginState', true);
    },
    logout({ commit }) {
      commit('setLoginState', false);
    },
    setFilter({ commit }, payload) {
      commit('setFilter', payload);
    },
    togglePanel({ commit }, { panelType, panelName }) {
      commit('togglePanel', { panelType, panelName });
    },
    initializeState({ commit, state }) {
      if (state.isFirstLoad) {
        commit('setFirstLoad', false);
      }
    },
    updateAllPanels({ commit }, newPanels) {
      commit('updateAllPanels', newPanels);
    }
  },
  plugins: [
    createPersistedState({
      paths: ['isLoggedIn', 'filters', 'paneles', 'isFirstLoad'],
    }),
  ],
});
