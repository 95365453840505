<template>
    <div>
      <h2>Users Metrics</h2>
      <BarChart :chartData="data" :options="options" />
    </div>
  </template>
  
  <script>
  import { BarChart } from 'vue-chart-3';
  
  export default {
    components: {
      BarChart,
    },
    data() {
      return {
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [40, 39, 10, 40, 39, 80, 40],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
  };
  </script>
  
<style lang="sass" scoped>

</style>
