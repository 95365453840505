<template>
    <div>
      <div>
        <button @click="updateAllPanels">Update All Panels</button>
      </div>
      <button @click="selectTab('GeneralMetrics')">Métricas Generales</button>
      <button @click="selectTab('EventMetrics')">Métricas por Evento</button>
      <button @click="selectTab('UserMetrics')">Usuarios</button>
      
      <component :is="currentTab"></component>
    </div>
  </template>
  
  <script>
  import GeneralMetrics from './metrics/GeneralMetrics.vue';
  import EventMetrics from './metrics/EventMetrics.vue';
  import UserMetrics from './metrics/UserMetrics.vue';
  
  export default {
    data() {
      return {
        currentTab: 'GeneralMetrics',
      };
    },
    components: {
      GeneralMetrics,
      EventMetrics,
      UserMetrics,
    },
    methods: {
      selectTab(tab) {
        this.currentTab = tab;
      },
      updateAllPanels() {
      const newPanels = {
          general: [
            { name: 'eventsCountByYearMonth', visible: true },
            { name: 'eventsCountByLocation', visible: true },          
          // { name: 'eventsCountByYearMonth2', visible: true },
          // { name: 'eventsCountByYearMonth', visible: true },
          
          ],
          event: [            
          ],
          user: [            
          ],
        };
        this.$store.dispatch('updateAllPanels', newPanels);
      }
    },
  };
  </script>
  
<style lang="sass" scoped>

</style>
