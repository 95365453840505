<template>
  <div v-for="(panelList, type) in validPanels" :key="type">
    <h2>{{ type }} Configurator</h2>
    <div v-for="panel in panelList" :key="panel.name">
      <input type="checkbox" :checked="panel.visible" @change="() => togglePanel({ panelType: type, panelName: panel.name })">
      <label>{{ panel.name }}</label>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['paneles']),
    validPanels() {
      console.log(this.paneles)
      const filteredPanels = Object.keys(this.paneles).reduce((acc, key) => {
        if (Array.isArray(this.paneles[key])) {
          acc[key] = this.paneles[key];
        }
        return acc;
      }, {});
      console.log(filteredPanels); // Esto mostrará los datos filtrados en la consola
      return filteredPanels;
    }

  },
  methods: {
    ...mapActions(['togglePanel']),
  },
};
</script>
