<template>
  <div>
    <TabNavigation />
    <router-view />
  </div>
</template>

<script>
import TabNavigation from '../components/TabNavigation.vue';

export default {
  name: 'UserDashboard',
  components: {
    TabNavigation,
  },
};
</script>

  
<style lang="sass" scoped>

</style>
