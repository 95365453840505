import axios from 'axios';
// import { raw } from 'core-js/core/string';

export const geteventsCountByYearMonth = async (year, month) => {
  const params = {};
  if (year) params.year = year;
  if (month) params.month = month;

  try {
    // const response = await axios.get('/api/events-count');
    const response = await axios.post('/api/general/eventsCountByYearMonthWeek' , { params }); // Cambié POST a GET
    const rawData = response.data;

    if(rawData.length > 0){
      console.log(rawData);

    }
    // Formatear los datos para Chart.js
    const labels = rawData.map(item => `Year: ${item.año}, Month: ${item.mes}`);
    const values = rawData.map(item => item.total_eventos);

    return { labels, values };
  } catch (error) {
    console.error('Error fetching General Metric 1', error);
    throw error;
  }
};
