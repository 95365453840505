<template>
    <TransitionRoot :show="isOpen" as="template" @close="close">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-50"></div>
          </TransitionChild>
  
          <span class="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <slot></slot>
              <button @click="close" class="mt-4 px-4 py-2 bg-gray-800 text-white rounded">Close</button>
            </div>
          </TransitionChild>
        </div>
      </div>
    </TransitionRoot>
  </template>
  
  <script>
  import { TransitionRoot, TransitionChild } from '@headlessui/vue';
  
  export default {
    components: {
      TransitionRoot,
      TransitionChild,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      close() {
        this.$emit('update:isOpen', false);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Añade estilos adicionales si es necesario */
  </style>
  