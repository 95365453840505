import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Chart, registerables } from 'chart.js';
import VueChart3 from 'vue-chart-3';

Chart.register(...registerables);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueChart3);

app.mount('#app');
