import axios from 'axios';

export const getEventsCountByLocation = async (year, month, city) => {
  const params = {};
  if (year) params.year = year;
  if (month) params.month = month;
  if (city) params.city = city;

  try {
    const response = await axios.post('/api/general/eventsCountByLocation', { params });
    const rawData = response.data;
    // const labels = rawData.map(item => `${item.ciudad}`);
    // const values = rawData.map(item => item.total_eventos);
    return rawData;
    // return { labels, values };
  } catch (error) {
    console.error('Error fetching General Metric 2', error);
    throw error;
  }
};
