<template>
  <div>
    <h1>Métricas Generales</h1>
    <button @click="openModal">Configurar Paneles</button>
    <!-- Selectores para Año y Mes -->
    <div>
      <select v-model="selectedYear">
        <option disabled value="">Seleccione un año</option>
        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
      </select>
      <select v-model="selectedMonth">
        <option value="">Todos los meses</option>
        <option v-for="month in 12" :key="month" :value="month">{{ month }}</option>
      </select>
      <button @click="applyFilters">Aplicar</button>
    </div>

    <Modal :isOpen="isModalOpen" @update:isOpen="isModalOpen = $event">
      <PanelConfigurator />
    </Modal>

    <div v-for="panelName in visiblePanelNames" :key="panelName">
      <component :is="getComponentName(panelName)" :year="selectedYear" :month="selectedMonth" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/Modal.vue';
import PanelConfigurator from '@/components/PanelConfigurator.vue';
import eventsCountByYearMonth from './GeneralMetrics/eventsCountByYearMonth/eventsCountByYearMonth.vue';
import eventsCountByLocation from './GeneralMetrics/eventsCountByLocation/eventsCountByLocation.vue';
// import GeneralMetric2 from './GeneralMetrics/GeneralMetric2/GeneralMetric2.vue';

export default {
  components: {
    Modal,
    PanelConfigurator,
    eventsCountByYearMonth,
    eventsCountByLocation,
    // GeneralMetric2,
  },
  data() {
    return {
      isModalOpen: false,
      selectedYear: '',
      selectedMonth: '',
      years: [2021, 2022, 2023, 2024]  // Suponiendo que estos son los años de interés
    };
  },
  computed: {
    ...mapState(['paneles']),
    visiblePanelNames() {
      return this.paneles.general.filter(p => p.visible).map(p => p.name);
    },
  },
  methods: {
    ...mapActions(['togglePanel', 'applyFilters']),
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    applyFilters() {
      this.$store.dispatch('applyFilters', { year: this.selectedYear, month: this.selectedMonth });
    },
    getComponentName(panelName) {
      const components = {
        'eventsCountByYearMonth': eventsCountByYearMonth,
        'eventsCountByLocation': eventsCountByLocation,
        // 'GeneralMetric2': GeneralMetric2
      };
      return components[panelName] || null; // devuelve null si no se encuentra
    }
  },
};
</script>
