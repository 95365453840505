import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import UserLogin from '../views/UserLogin.vue';
import UserDashboard from '../views/UserDashboard.vue';
import GeneralMetrics from '../components/metrics/GeneralMetrics.vue';
import EventMetrics from '../components/metrics/EventMetrics.vue';
import UserMetrics from '../components/metrics/UserMetrics.vue';

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'general-metrics',
        component: GeneralMetrics,
      },
      {
        path: 'event-metrics',
        component: EventMetrics,
      },
      {
        path: 'user-metrics',
        component: UserMetrics,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
